'use client';
import { Button } from '@chakra-ui/button';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/layout';
import cx from 'classnames/bind';
import React from 'react';

import Styles from './FilterSection.module.scss';

const cn = cx.bind(Styles);

type Props = {
  selectedVehicleType: 'all' | 'new' | 'used';
  onVehicleTypeChange: (arg: 'all' | 'new' | 'used') => void;
  isMicrosite?: boolean;
};

const FilterSection = ({
  selectedVehicleType,
  onVehicleTypeChange,
  isMicrosite = false,
}: Props) => {
  const renderButton = (type: 'all' | 'new' | 'used', label: string) => (
    <Button
      className={cn(Styles.menuButton, {
        active: selectedVehicleType === type,
      })}
      onClick={() => onVehicleTypeChange(type)}
      rightIcon={selectedVehicleType === type ? <CheckIcon /> : undefined}
    >
      {label}
    </Button>
  );

  const renderButtons = () => (
    <Flex
      justifyContent={{ base: 'flex-start', md: 'center' }}
      flexWrap={'nowrap'}
      overflowX={'auto'}
      columnGap={{ base: '2', sm: '4' }}
      paddingX={{ base: 2, md: 0 }}
      width="100%"
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
      }}
    >
      {isMicrosite && renderButton('all', 'All')}
      {renderButton('new', 'New Cars')}
      {renderButton('used', 'Pre-Owned')}
    </Flex>
  );

  return <Box className={Styles.filterWrapper}>{renderButtons()}</Box>;
};

export default FilterSection;
