'use client';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Box, Container, SimpleGrid } from '@chakra-ui/layout';
import { Hide } from '@chakra-ui/media-query';
import {
  memo,
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import slugify from 'react-slugify';

import DiscoverySection from '@/app/_components/discovery/DiscoverySection';
import PromotionsCard from '@/app/_components/promotions-card/PromotionsCard';
import { getImageLink } from '@/services/link.service';
import { CallToAction } from '@/types/home/home.types';
import { Range } from '@/types/request/request.types';
import { Special } from '@/types/specials/specials.types';
import { SpecialFor, VehicleType } from '@/types/used-cars/used-cars.types';

import BannerSection from './BannerSection';
import './PromotionsPage.scss';
import FilterSection from './FilterSection';

type SpecialsLanding = {
  main: {
    title: string;
    description: string;
    image: string;
    mobileImage: string;
    url: string;
  };
  footer: {
    footer_call_to_action: CallToAction;
    footer_call_to_action_image: string;
  };
};

interface Props {
  specialsLanding: SpecialsLanding;
  specials: Special[];
  ranges: Range[];
  specialsCategories?: any[];
  defaultFilters: { [key: string]: string };
  hrefBase?: string;
  specialType?: 'all' | VehicleType.New | VehicleType.Used;
  specialFor?: SpecialFor | '';
  isMicrosite?: boolean;
}

type SimpleGridComponentProps = {
  className?: string;
  children: ReactNode;
  columns?: number[];
  spacingX?: string[];
  spacingY?: string[];
  paddingX?: { [key: string]: string };
};

const SimpleGridComponent = ({
  className,
  children,
  columns = [1, 1, 2, 3],
  spacingX = ['0px', '0px', '40px', '40px'],
  spacingY = ['40px', '40px', '80px', '80px'],
  paddingX = {},
}: SimpleGridComponentProps) => {
  return (
    <SimpleGrid
      className={className}
      columns={columns}
      spacingX={spacingX}
      spacingY={spacingY}
      paddingX={paddingX}
    >
      {children}
    </SimpleGrid>
  );
};

const PromotionsPage = ({
  specialsLanding,
  specials,
  ranges,
  defaultFilters,
  specialType = VehicleType.New,
  specialFor,
  isMicrosite = false,
}: Props) => {
  const [selectedFilter, setSelectedFilter] = useState(() => ({
    model: defaultFilters.model || '',
    ...defaultFilters,
  }));

  const [isRenderingClientSide, setIsRenderingClientSide] = useState(false);
  const [filteredSpecials, setFilteredSpecials] = useState(() => {
    // Initialize with filtered specials based on the initial type
    let initialSpecials = specials;

    if (specialType === VehicleType.Used) {
      initialSpecials = specials.filter(
        (special) =>
          special.vehicle_type === VehicleType.Used ||
          special.vehicle_type === VehicleType.Konfidence ||
          special.vehicle_type === VehicleType.Demo,
      );
    } else if (specialType === VehicleType.New) {
      initialSpecials = specials.filter(
        (special) => special.vehicle_type === VehicleType.New,
      );
    }

    if (defaultFilters.model) {
      initialSpecials = initialSpecials.filter(
        (special) =>
          defaultFilters.model &&
          special?.range?.id &&
          +defaultFilters.model === +special?.range?.id,
      );
    }

    return initialSpecials;
  });

  const [selectedVehicleType, setSelectedVehicleType] = useState<
    'all' | 'new' | 'used'
  >(() => {
    // Initialize based on specialType and isMicrosite
    if (isMicrosite) {
      return specialType === VehicleType.New ? 'new' :
             specialType === VehicleType.Used ? 'used' :
             'all';
    }
    return specialType === VehicleType.Used ? 'used' : 'new';
  });

  const getModel = useCallback(
    (id: number): string | null => {
      let model = ranges.findIndex((range) => range.id === id);

      if (!model || !id) {
        return null;
      }
      return slugify(ranges[model]?.name);
    },
    [ranges],
  );

  const getFilteredSpecials = useCallback(() => {
    let updatedSpecials = specials;

    switch (selectedVehicleType) {
      case 'used':
        updatedSpecials = updatedSpecials.filter(
          (special) =>
            special.vehicle_type === VehicleType.Used ||
            special.vehicle_type === VehicleType.Konfidence ||
            special.vehicle_type === VehicleType.Demo,
        );
        break;
      case 'new':
        updatedSpecials = updatedSpecials.filter(
          (special) => special.vehicle_type === VehicleType.New,
        );
        break;
      case 'all':
      default:
        break;
    }

    if (selectedFilter['model']) {
      updatedSpecials = updatedSpecials.filter(
        (special) =>
          selectedFilter['model'] &&
          special?.range?.id &&
          +selectedFilter['model'] === +special?.range?.id,
      );
    }
    return updatedSpecials;
  }, [specials, selectedVehicleType, selectedFilter]);

  const handleVehicleTypeChange = useCallback(
    (type: 'all' | 'new' | 'used') => {
      setSelectedVehicleType(type);

      // Reset the model filter using setState
      setSelectedFilter((prev) => ({
        ...prev,
        model: '', // Clear the model filter
      }));

      // Simply navigate to the vehicle type URL without model
      const newUrl = type === 'all' ? '/promotions' : `/promotions/${type}`;

      window.history.pushState({}, '', newUrl);
    },
    [],
  );

  useEffect(() => {
    if (!isRenderingClientSide) {
      setIsRenderingClientSide(true);
    }
  }, []);

  useEffect(() => {
    setFilteredSpecials(getFilteredSpecials());
  }, [getFilteredSpecials]);

  const getBreadcrumbItems = useCallback(() => {
    const items = [];

    // Always add Promotions as first item
    items.push({
      label: 'Promotions',
      href: '/promotions',
    });

    // Add vehicle type if selected
    if (selectedVehicleType !== 'all') {
      items.push({
        label: selectedVehicleType === 'new' ? 'New Cars' : 'Pre-Owned',
        href: `/promotions/${selectedVehicleType}`,
      });
    }

    // Add model if filtered
    if (selectedFilter.model) {
      const selectedRange = ranges.find(
        (model) => selectedFilter.model && +model.id === +selectedFilter.model,
      );

      if (selectedRange) {
        items.push({
          label: selectedRange.name,
          href: `/promotions/${slugify(selectedRange.name)}`,
        });
      }
    }

    return items;
  }, [selectedVehicleType, selectedFilter.model, ranges]);

  const getPageTitle = () => {
    if (selectedVehicleType === 'used') {
      return 'Used Car Promotions';
    } else if (selectedVehicleType === 'new') {
      return 'New Car Promotions';
    }
    return 'New and Used Car Promotions';
  };

  return (
    <>
      <BannerSection
        props={{
          ...specialsLanding?.main,
          title: getPageTitle(),
        }}
      />
      <Suspense fallback={null}>
        {specialFor === 'vehicle' || specialFor === '' ? (
          <FilterSection
            selectedVehicleType={selectedVehicleType}
            onVehicleTypeChange={handleVehicleTypeChange}
            isMicrosite={isMicrosite}
          />
        ) : null}
        <Box className="darkBg">
          <Container
            maxWidth={{ base: '100%', md: '1328px' }}
            alignContent={'center'}
            paddingX={{ base: 6 }}
            paddingTop={{ lg: '15px' }}
            paddingY={{ base: '35px' }}
          >
            <Hide breakpoint="(max-width: 768px)">
              <Breadcrumb color={'white'} marginBottom={'45px'}>
                {getBreadcrumbItems().map((item, index) => (
                  <BreadcrumbItem
                    key={index}
                    isCurrentPage={index === getBreadcrumbItems().length - 1}
                  >
                    <BreadcrumbLink href={item.href}>
                      {item.label}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                ))}
              </Breadcrumb>
            </Hide>
            <SimpleGridComponent className="promotionsList">
              {filteredSpecials &&
                filteredSpecials?.map((item: Special) => (
                  <PromotionsCard
                    item={{ ...item, image: getImageLink(item?.image) ?? '' }}
                    key={item.id}
                    hrefBase={
                      item.range && getModel(+item?.range?.id)
                        ? `/promotions/${getModel(+item.range.id)}`
                        : '/promotions'
                    }
                  />
                ))}
            </SimpleGridComponent>
          </Container>
        </Box>
        <DiscoverySection
          footer_call_to_action={
            specialsLanding?.footer?.footer_call_to_action
              ? specialsLanding?.footer?.footer_call_to_action
              : null
          }
          footer_call_to_action_image={getImageLink(
            specialsLanding?.footer?.footer_call_to_action_image,
          )}
          imageKeys={{
            desktopKey: 'promotionspage-cta-footer-desktop',
            mobileKey: 'promotionspage-cta-footer-mobile',
          }}
        />
      </Suspense>
    </>
  );
};

export default memo(PromotionsPage);
